import autoBind from 'class-autobind';
import { Button, Modal, Table } from 'react-bootstrap';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loading from '../shared/Loading';
import { parseApplicationNumber } from '../../utils/parse-application-number';
import { formatDate } from '../../utils/date';

export default class PatentFamilyModal extends Component {
  static propTypes = {
    addFirstMatterTwoWayRelation: PropTypes.func.isRequired,
    addMatterTwoWayRelation: PropTypes.func.isRequired,
    checkEspace: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    fetchMatter: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired,
    fetchPatentFamily: PropTypes.func.isRequired,
    fetchPublicPairStatus: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      data: null,
      loading: false,
      saving: false,
      checked: [],
      closed: []
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const data = await this.props.fetchPatentFamily(this.props.matter);

    const family = (data?.[0]?.family ?? []).filter(row => (
      !this.isSameApplicationNumber(this.props.matter, row)
    ));

    family.forEach(row => {
      row.applicationNumber = parseApplicationNumber(row.countryCode + row.appNum);
      row.matter = this.props.matterList.find(matter => this.isSameApplicationNumber(matter, row));
      row.matterNumber = row.matter ? row.matter.get('matterNumber') : row.countryCode + row.appNum;
      row.shouldFetchPairStatus = row.countryCode === 'US' && !row.appNum.startsWith('PCT');
    });

    await Promise.all(family.map(async(row) => {
      if (!row.shouldFetchPairStatus) {
        return;
      }

      if (row.matter) {
        const matterId = row.matter.get('id');
        const response = await this.props.fetchMatter(matterId);
        const matter = response.entities.matters[matterId];
        if (matter) {
          if (matter.pairStatus) {
            row.pairStatus = {
              status: matter.pairStatus,
              date: matter.pairStatusDate
            };
          } else if (matter.patentCenterPairStatus) {
            row.pairStatus = {
              status: matter.patentCenterPairStatus,
              date: matter.patentCenterPairStatusDate
            };
          }
        }
      }
      if (!row.pairStatus) {
        try {
          const { status, date } = await this.props.fetchPublicPairStatus(row.applicationNumber);
          row.pairStatus = { status, date: formatDate(date) };
        } catch {
          //
        }
      }
    }));

    this.setState({
      data: family,
      loading: false,
      checked: family.map((row, i) => row.shouldFetchPairStatus ? null : i).filter(i => i !== null),
      closed: family.map((row, i) => i)
    });
  }

  isSameApplicationNumber(matter, row) {
    const matterAppNumbers = [
      (matter.get('applicationNumber') || '').replace(/[\D]/g, ''),
      (matter.get('applicationNumber') || '').replace(/[/,]/g, ''),
      matter.get('applicationNumber')
    ];

    const rowAppNumbers = [
      row.appNum,
      row.countryCode + row.appNum,
      parseApplicationNumber(row.countryCode + row.appNum)
    ];

    return rowAppNumbers.some(number => matterAppNumbers.includes(number));
  }

  getRows() {
    return this.state.data || [];
  }

  onClickCancel() {
    this.setState({ show: false });
    this.props.onCancel();
  }

  async updateOrCreateMatters(selectedRows, closedRows) {
    const mattersToCheckEspace = [];

    if (this.state.checkEspaceActive) {
      mattersToCheckEspace.push(this.props.matter.get('id'));
    }

    for (const row of selectedRows) {
      const existingMatter = this.props.matterList.find(matter => (
        matter.get('matterNumber') === row.matterNumber
      ));

      const values = {
        applicationNumber: row.applicationNumber
      };

      if (closedRows.includes(row)) {
        values.matterClosed = 'ON';
      }

      if (existingMatter) {
        await this.props.updateMatter({
          id: existingMatter.get('id'),
          ...values
        }, row.matterNumber);

        mattersToCheckEspace.push(existingMatter.get('id'));
      } else {
        const { id } = await this.props.createMatter({
          clientNumber: this.props.matter.get('clientNumber'),
          matterNumber: row.matterNumber,
          ...values
        });

        mattersToCheckEspace.push(id);
      }
    }

    return mattersToCheckEspace;
  }

  async checkEspace(mattersToCheckEspace) {
    for (const matterId of mattersToCheckEspace) {
      await this.props.checkEspace(Immutable.fromJS({ id: matterId }), true);
    }
  }

  async addTwoWayRelations(matterNumbers) {
    const matterId = this.props.matter.get('id');
    if (!this.props.matter.get('twoWayRelatedMatters')) {
      await this.props.addFirstMatterTwoWayRelation(matterId, matterNumbers.shift());
    }
    for (const matterNumber of matterNumbers) {
      await this.props.addMatterTwoWayRelation(matterId, matterNumber);
    }
  }

  async onClickConfirm() {
    this.setState({ saving: true });

    const rows = this.getRows();
    const selectedRows = this.state.checked.map(i => rows[i]);
    const closedRows = this.state.closed.map(i => rows[i]);
    const matterNumbers = selectedRows.map(row => row.matterNumber);
    const mattersToCheckEspace = await this.updateOrCreateMatters(selectedRows, closedRows);

    await this.checkEspace(mattersToCheckEspace);
    await this.addTwoWayRelations(matterNumbers);
    await this.props.fetchFamily(this.props.matter, true);

    this.setState({ show: false, saving: false });
    this.props.onConfirm();
  }

  renderConfirm() {
    return (
      <Button
        variant='primary'
        onClick={this.onClickConfirm}
        disabled={!this.state.checked.length || this.state.saving}
      >
        {this.state.saving ? 'Saving...' : 'Confirm'}
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        style={{ marginRight: '10px' }}
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  renderButtons() {
    return (
      <div className='pull-right'>
        {this.renderCancel()}
        {this.renderConfirm()}
      </div>
    );
  }

  onChangeInclude(e) {
    const value = Number(e.target.value);
    if (this.state.checked.includes(value)) {
      this.setState({ checked: this.state.checked.filter(i => i !== value) });
    } else {
      this.setState({ checked: this.state.checked.concat(value) });
    }
  }

  renderInclude(row, i) {
    return (
      <input
        type='checkbox'
        value={i}
        checked={this.state.checked.includes(i)}
        onChange={this.onChangeInclude}
      />
    );
  }

  onChangeStopIDS(e) {
    const value = Number(e.target.value);
    if (this.state.closed.includes(value)) {
      this.setState({ closed: this.state.closed.filter(i => i !== value) });
    } else {
      this.setState({ closed: this.state.closed.concat(value) });
    }
  }

  renderStopIDS(row, i) {
    return (
      <input
        type='checkbox'
        value={i}
        checked={this.state.closed.includes(i)}
        onChange={this.onChangeStopIDS}
      />
    );
  }

  onSelectAllInclude() {
    if (this.state.checked.length === this.state.data.length) {
      this.setState({ checked: [] });
    } else {
      this.setState({ checked: this.state.data.map((row, i) => i) });
    }
  }

  onSelectAllStopIDS() {
    if (this.state.closed.length === this.state.data.length) {
      this.setState({ closed: [] });
    } else {
      this.setState({ closed: this.state.data.map((row, i) => i) });
    }
  }

  renderTable() {
    const rows = this.getRows();

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '30px' }}>
              <input
                type='checkbox'
                checked={this.state.checked.length === rows.length}
                onChange={this.onSelectAllInclude}
              />
            </th>
            <th>Matter ID</th>
            <th>Application #</th>
            <th>Pair Status</th>
            <th style={{ textAlign: 'center', width: '100px' }}>
              <input
                type='checkbox'
                checked={this.state.closed.length === rows.length}
                onChange={this.onSelectAllStopIDS}
                style={{ marginRight: '5px' }}
              />
              <span>Stop IDS</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return (
              <tr key={i}>
                <td style={{ width: '30px' }} className='text-center'>
                  {this.renderInclude(row, i)}
                </td>
                <td>
                  {row.matterNumber}
                </td>
                <td>
                  {row.applicationNumber}
                </td>
                <td>
                  {row.pairStatus ? row.pairStatus.status : '-'}
                </td>
                <td style={{ textAlign: 'center', width: '100px' }}>
                  {this.renderStopIDS(row, i)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderBody() {
    if (this.state.loading) {
      return (
        <Loading />
      );
    }

    return (
      <div>
        <p>Select the matters to be included:</p>
        {this.renderTable()}
      </div>
    );
  }

  onChangeCheckEspaceActive() {
    this.setState({ checkEspaceActive: !this.state.checkEspaceActive });
  }

  renderCheckEspaceActiveOption() {
    return (
      <div className='checkbox'>
        <label>
          <input
            onChange={this.onChangeCheckEspaceActive}
            type='checkbox'
            name='checkEspaceActive'
            checked={this.state.checkEspaceActive}
          />
          &nbsp; Check Espace for the active matter ({this.props.matter.get('applicationNumber')})
        </label>
      </div>
    );
  }

  render() {
    return (
      <Modal
        keyboard={false}
        size='lg'
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Patent Family ({this.props.matter.get('applicationNumber')})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          {this.renderCheckEspaceActiveOption()}
          {this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}
